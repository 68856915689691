





















































import Vue from "vue";
import Component from "vue-class-component";
import { hasPermissionSync } from "../../utils/auth";
import { Permission } from "../../typing/permissions.enum";

@Component({
  props: {
    selectedMonitorName: {
      type: String,
      required: true,
    },
    componentDescriptionGraph: {
      type: String,
      required: true,
    },
    updatedAtFormatted: {
      type: String,
      required: true,
    },
    showBack: {
      type: Boolean,
      required: true,
    },
    pid: String,
  },
})
export default class GraphHeader extends Vue {
  localMonitorName: string = this.$props.selectedMonitorName;
  localDescription: string = this.$props.componentDescriptionGraph;
  toggleEditMonitor = false;
  toggleEditDescription = false;

  isAdminAppUpdate(): boolean {
    return hasPermissionSync(Permission.adminappUpdate);
  }

  toggleMonitor() {
    this.toggleEditMonitor = !this.toggleEditMonitor;
  }

  toggleDescription() {
    this.toggleEditDescription = !this.toggleEditDescription;
  }

  cancelEditMonitor() {
    this.toggleEditMonitor = false;
    this.localMonitorName = this.$props.selectedMonitor;
  }

  cancelEditDescription() {
    this.toggleEditDescription = false;
    this.localDescription = this.$props.componentDescriptionGraph;
  }

  saveMonitor() {
    this.toggleEditMonitor = false;
    this.toggleEditDescription = false;
    this.$emit("updateMonitor", this.localMonitorName);
    this.$emit("save");
  }

  saveDescription() {
    this.toggleEditMonitor = false;
    this.toggleEditDescription = false;
    this.$emit("updateDescription", this.localDescription);
    this.$emit("save");
  }

  openInfo() {
    this.$emit("toggleInfo");
  }

  back() {
    this.$router.push({
      name: "ProductDetail",
      params: { id: this.$props.pid },
    });
  }
}
