





















































































import Component from "vue-class-component";
import Vue from "vue";
import Loader from "@/components/Loader.vue";
import Monitor from "@/components/Monitor.vue";
import ServerComponent from "@/components/ServerComponent.vue";
import LineChart from "@/components/Line.vue";
import { hasPermissionSync } from "@/utils/auth";
import { Permission } from "@/typing/permissions.enum";
import BoundaryHistory from "@/components/Graph/BoundaryHistory.vue";
import ThresholdEditor from "@/components/Graph/ThresholdEditor.vue";
import GraphHeader from "@/components/Graph/GraphHeader.vue";
import GraphDetail from "@/typing/webbeat/graphs/graphDetail.class";

@Component({
  components: {
    Loader,
    Monitor,
    ServerComponent,
    LineChart,
    BoundaryHistory,
    ThresholdEditor,
    GraphHeader,
  },
  props: {
    graphDetail: {
      type: GraphDetail,
      required: true,
    },
    showBack: {
      type: Boolean,
      required: true,
    },
    pid: String,
  },
})
export default class BaseGraphDetail extends Vue {
  graphDetail = this.$props.graphDetail;
  modalInfoShow = false;
  modalShow = false;
  pastTimeOptions = [
    { item: 1, name: "24 uur" },
    { item: 7, name: "Week" },
    { item: 14, name: "Twee weken" },
    { item: 30, name: "Maand" },
  ];
  messageTypeOptions = ["Active", "Dead Letter", "Scheduled Msgs"];

  isAdminAppUpdate(): boolean {
    return hasPermissionSync(Permission.adminappUpdate);
  }

  async mounted(): Promise<void> {
    this.graphDetail.isLoading = true;
    this.scrollToTop();
    this.loadData();
    this.graphDetail.isLoading = false;
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  }

  toggleInfo() {
    this.modalInfoShow = !this.modalInfoShow;
  }

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  changeLowerBound(any: number) {
    this.graphDetail.lowerBound = any;
  }

  changeUpperBound(any: number) {
    this.graphDetail.upperBound = any;
  }

  handleUpdateMonitor(any: string) {
    this.graphDetail.monitorName = any;
  }

  handleUpdateDescription(any: string) {
    this.graphDetail.description = any;
  }

  loadData() {
    this.$emit("loadData");
    this.scrollToTop();
  }

  save() {
    this.$emit("save");
  }

  loadDataGraph() {
    this.$emit("save");
  }
}
