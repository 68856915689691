import { ChartValueItem } from "./graphs/chartValueItem.class";
import SubscriptionValue from "./subscriptionValue.class";

export default class SubscriptionHistory extends ChartValueItem {
  beginDate: Date;
  topicSubscriptionName: string;
  healthClientName: string;
  values: SubscriptionValue[];

  constructor(t?: SubscriptionHistory) {
    super(t);
    this.beginDate = t?.beginDate ?? new Date();
    this.topicSubscriptionName = t?.topicSubscriptionName ?? "";
    this.healthClientName = t?.healthClientName ?? "";
    this.values = t?.values ?? [];
  }
}
