




import Component from "vue-class-component";
import ToastrHelper from "@/helpers/toastHelper";
import { GetTrendHistory, UpdateTrend } from "@/api/apiWebbeat";
import { dayjsEx, fromatDateTime, updateAtFormat } from "@/utils/dayjs";
import TrendHistory from "@/typing/webbeat/trendHistory.class";
import TrendGraph from "@/typing/webbeat/graphs/trendGraph.class";
import TrendUpdateModel from "@/typing/webbeat/trendUpdateModel.class";
import AlertConfigHistoryResponseModel from "@/typing/webbeat/alertConfigHistoryResponseModel.class";
import ChartDataModel from "@/typing/webbeat/chartDataModel.class";
import Vue from "vue";
import BaseGraphDetail from "@/views/webbeat/BaseGraphDetail.vue";
import GraphDetail from "@/typing/webbeat/graphs/graphDetail.class";

@Component({
  components: {
    BaseGraphDetail,
  },
  props: {
    orgId: String,
    propName: String,
    pid: String,
    onRefresh: Function,
  },
})
export default class GraphDetailTrend extends Vue {
  get loadedAsPage(): boolean {
    return window.__app__.$route.name === "GraphDetailTrend";
  }

  trendHistory: TrendHistory = new TrendHistory();
  trendGraph: TrendGraph = new TrendGraph();
  updateTrend: TrendUpdateModel = new TrendUpdateModel();
  graphDetail: GraphDetail = new GraphDetail();

  async loadData() {
    try {
      this.graphDetail.isLoading = true;
      const result = await GetTrendHistory(this.$props.orgId, this.$props.propName, this.graphDetail.pastTimeSelected);
      const trendGraph = result.data.body;
      this.prepareViewData(trendGraph);
      this.graphDetail.chartData = result.data.body.history.values.map(
        (x) =>
          new ChartDataModel({
            date: x.receivedAtDate.toLocaleString("MM/dd"),
            value: x.count,
            receivedAtDate: x.receivedAtDate,
            formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
            isAlertActive: x.isAlertActive,
          }),
      );

      this.graphDetail.chartDataLine = [];
      this.graphDetail.chartLower = [];
      this.graphDetail.chartUpper = [];
      this.graphDetail.chartGap = [];
      this.graphDetail.chartLabels = [];

      this.graphDetail.highestValue = 0;
      this.graphDetail.chartData.forEach((chart: ChartDataModel) => {
        this.graphDetail.chartDataLine.push(chart.value);
        this.graphDetail.chartLabels.push(chart.formattedDate);
        this.graphDetail.chartLower.push(trendGraph.lowerBound);
        this.graphDetail.chartUpper.push(trendGraph.upperBound);

        if (chart.value > this.graphDetail.highestValue) {
          this.graphDetail.highestValue = chart.value;
        }
      });

      if (trendGraph.upperBound > this.graphDetail.highestValue) {
        this.graphDetail.highestValue = trendGraph.upperBound;
      }
      this.graphDetail.highestValue = this.graphDetail.highestValue * 1.1;

      this.graphDetail.highestValue = parseInt(parseFloat(this.graphDetail.highestValue.toString()).toFixed(0));

      this.graphDetail.chartData.forEach((chart: ChartDataModel) => {
        if (chart.value > trendGraph.lowerBound && chart.value < trendGraph.upperBound) {
          this.graphDetail.chartGap.push(NaN);
        } else if (chart.isAlertActive) {
          this.graphDetail.chartGap.push(NaN);
        } else {
          this.graphDetail.chartGap.splice(this.graphDetail.chartGap.length - 1, 1, this.graphDetail.highestValue);
          this.graphDetail.chartGap.push(this.graphDetail.highestValue);
        }
      });

      this.graphDetail.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: this.graphDetail.highestValue,
            stepSize: 100,
            reverse: false,
          },
        },
      };
      this.graphDetail.chartDataSet = {
        labels: this.graphDetail.chartLabels,
        datasets: [
          {
            label: "Chart data",
            data: this.graphDetail.chartDataLine,
            borderColor: "#61719D",
            backgroundColor: "#61719D",
            cubicInterpolationMode: "monotone",
          },
          {
            label: "Lower bound",
            data: this.graphDetail.chartLower,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            pointStyle: "dash",
            hitRadius: 0,
            pointRadius: 0,
          },
          {
            label: "Upper bound",
            data: this.graphDetail.chartUpper,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            hitRadius: 0,
            pointRadius: 0,
            fill: "-1",
          },
          {
            label: "Out of bounds",
            data: this.graphDetail.chartGap,
            backgroundColor: "#FF1E044D",
            borderColor: "#FF1E044D",
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
        ],
      };
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.graphDetail.isLoading = false;
    }
  }

  async save() {
    this.graphDetail.isLoading = true;
    try {
      this.updateTrend.displayName = this.graphDetail.monitorName;
      this.updateTrend.description = this.graphDetail.description;
      this.updateTrend.lowerBound = Number(this.graphDetail.lowerBound);
      this.updateTrend.upperBound = Number(this.graphDetail.upperBound);
      this.updateTrend.healthMonitorName = this.graphDetail.healthMonitorName;
      const result = await UpdateTrend(this.$props.orgId, this.graphDetail.selectedId, this.updateTrend);

      this.graphDetail.description = result.data.body.description ?? "";
      this.graphDetail.monitorName = result.data.body.displayName;
      this.graphDetail.lowerBound = result.data.body.lowerBound;
      this.graphDetail.upperBound = result.data.body.upperBound;
      this.graphDetail.boundariesLabel = this.graphDetail.lowerBound.toString() + " - " + this.graphDetail.upperBound.toString();
      if (this.$props.onRefresh) {
        this.$props.onRefresh(); // Call the parent’s refresh method directly
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.graphDetail.isLoading = false;
  }
  prepareViewData(result: TrendGraph): void {
    this.graphDetail.updatedAtFormatted = dayjsEx.utc(result.updatedAt).format(updateAtFormat);
    this.updateTrend.displayName = result.displayName;
    this.updateTrend.description = result.description ?? "";
    this.updateTrend.healthMonitorName = result.healthMonitorName;
    this.updateTrend.lowerBound = result.lowerBound;
    this.updateTrend.order = result.order;
    this.updateTrend.upperBound = result.upperBound;
    this.graphDetail.trendKey = result.key;

    this.graphDetail.description = result.description ?? "";
    this.graphDetail.monitorName = result.displayName;
    this.graphDetail.monitorSelected = true;
    this.graphDetail.selectedId = result.id;

    this.graphDetail.boundariesLabel = result.lowerBound.toString() + " - " + result.upperBound.toString();
    this.graphDetail.lowerBound = result.lowerBound;
    this.graphDetail.upperBound = result.upperBound;

    this.graphDetail.alertConfigHistories = [];
    var list = result.alertConfigHistory?.map((item) => new AlertConfigHistoryResponseModel(item));
    this.graphDetail.alertConfigHistories = list ?? [];
    this.graphDetail.healthMonitorName = result.healthMonitorName;
  }
}
