














import { getInstance } from "@/utils/auth";
import Component from "vue-class-component";
import Vue from "vue";

@Component({})
export default class Header extends Vue {
  logout(): void {
    getInstance().logout();
  }

  goToHome(): void {
    this.$router.push({
      name: "Home",
    });
  }
}
