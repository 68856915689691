











import Component from "vue-class-component";
import Vue from "vue";
import ToastrHelper from "@/helpers/toastHelper";
import ChartDataModel from "@/typing/webbeat/chartDataModel.class";
import { GetSubscriptionHistory, UpdateSubscription } from "@/api/apiWebbeat";
import { dayjsEx, fromatDateTime, updateAtFormat } from "@/utils/dayjs";
import SubscriptionHistory from "@/typing/webbeat/subscriptionHistory.class";
import SubscriptionGraph from "@/typing/webbeat/graphs/subscriptionGraph.class";
import SubscriptionUpdateModel from "@/typing/webbeat/subscriptionUpdate.class";
import AlertConfigHistoryResponseModel from "@/typing/webbeat/alertConfigHistoryResponseModel.class";
import BaseGraphDetail from "@/views/webbeat/BaseGraphDetail.vue";
import GraphDetail from "@/typing/webbeat/graphs/graphDetail.class";

@Component({
  components: {
    BaseGraphDetail,
  },
  props: {
    orgId: String,
    propName: String,
    pid: String,
    onRefresh: Function,
  },
})
export default class GraphDetailSubscription extends Vue {
  get loadedAsPage(): boolean {
    return window.__app__.$route.name === "GraphDetailSubscription";
  }
  subscriptionHistory: SubscriptionHistory = new SubscriptionHistory();
  subGraph: SubscriptionGraph = new SubscriptionGraph();
  updateSubscription: SubscriptionUpdateModel = new SubscriptionUpdateModel();
  graphDetail: GraphDetail = new GraphDetail();

  async loadData() {
    try {
      this.graphDetail.isLoading = true;
      const result = await GetSubscriptionHistory(this.$props.orgId, this.$props.propName, this.graphDetail.pastTimeSelected);
      this.prepareViewData(result.data.body);
      this.loadDataGraph();
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.graphDetail.isLoading = false;
    }
  }

  async loadDataGraph() {
    try {
      this.graphDetail.isLoading = true;

      switch (this.graphDetail.messageTypeSelected) {
        case "Active":
          this.graphDetail.chartData = this.subscriptionHistory.values.map(
            (x) =>
              new ChartDataModel({
                date: x.receivedAtDate.toLocaleString("MM/dd"),
                value: x.activeMessageCount,
                receivedAtDate: x.receivedAtDate,
                formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
                isAlertActive: x.isAlertActive,
              }),
          );
          break;
        case "Dead Letter":
          this.graphDetail.chartData = this.subscriptionHistory.values.map(
            (x) =>
              new ChartDataModel({
                date: x.receivedAtDate.toLocaleString("MM/dd"),
                value: x.deadLetterMessageCount,
                receivedAtDate: x.receivedAtDate,
                formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
                isAlertActive: x.isAlertActive,
              }),
          );
          break;
        case "Scheduled Msgs":
          this.graphDetail.chartData = this.subscriptionHistory.values.map(
            (x) =>
              new ChartDataModel({
                date: x.receivedAtDate.toLocaleString("MM/dd"),
                value: x.scheduledMessageCount,
                receivedAtDate: x.receivedAtDate,
                formattedDate: dayjsEx.utc(x.receivedAtDate).format(fromatDateTime),
                isAlertActive: x.isAlertActive,
              }),
          );
          break;
      }

      this.graphDetail.chartDataLine = [];
      this.graphDetail.chartUpper = [];
      this.graphDetail.chartGap = [];
      this.graphDetail.chartLabels = [];

      this.graphDetail.highestValue = 0;
      let threshold = 0;

      switch (this.graphDetail.messageTypeSelected) {
        case "Active":
          threshold = this.updateSubscription.activeMessageThreshold;
          break;
        case "Dead Letter":
          threshold = this.updateSubscription.deadLetterMessageThreshold;
          break;
        case "Scheduled Msgs":
          threshold = this.updateSubscription.scheduledMessageThreshold;
          break;
      }

      this.graphDetail.chartData.forEach((chart: ChartDataModel) => {
        this.graphDetail.chartDataLine.push(chart.value);
        this.graphDetail.chartLabels.push(chart.formattedDate);
        this.graphDetail.chartUpper.push(threshold);

        if (chart.value > this.graphDetail.highestValue) {
          this.graphDetail.highestValue = chart.value;
        }
      });

      if (threshold > this.graphDetail.highestValue) {
        this.graphDetail.highestValue = threshold;
      }

      this.graphDetail.highestValue = this.graphDetail.highestValue * 1.1;
      this.graphDetail.upperBound = threshold;
      this.graphDetail.highestValue = parseInt(parseFloat(this.graphDetail.highestValue.toString()).toFixed(0));

      this.graphDetail.chartData.forEach((chart: ChartDataModel) => {
        if (chart.value < threshold) {
          this.graphDetail.chartGap.push(NaN);
        } else {
          this.graphDetail.chartGap.splice(this.graphDetail.chartGap.length - 1, 1, this.graphDetail.highestValue);
          this.graphDetail.chartGap.push(this.graphDetail.highestValue);
        }
      });
      this.graphDetail.boundariesLabel = 0 + " - " + threshold;

      this.graphDetail.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            min: 0,
            max: this.graphDetail.highestValue,
            stepSize: 100,
            reverse: false,
          },
        },
      };
      this.graphDetail.chartDataSet = {
        labels: this.graphDetail.chartLabels,
        datasets: [
          {
            label: "Chart data",
            data: this.graphDetail.chartDataLine,
            borderColor: "#61719D",
            backgroundColor: "#61719D",
            cubicInterpolationMode: "monotone",
          },
          {
            label: "Upper bound",
            data: this.graphDetail.chartUpper,
            borderColor: "#d2d2d2",
            borderDash: [5, 5],
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
          {
            data: this.graphDetail.chartGap,
            backgroundColor: "#FF1E044D",
            borderColor: "#FF1E044D",
            hitRadius: 0,
            pointRadius: 0,
            fill: true,
          },
        ],
      };
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    } finally {
      this.graphDetail.isLoading = false;
    }
  }

  async save() {
    this.graphDetail.isLoading = true;
    try {
      this.updateSubscription.displayName = this.graphDetail.monitorName;
      this.updateSubscription.description = this.graphDetail.description;

      if (this.graphDetail.messageTypeSelected === "Active") {
        this.updateSubscription.activeMessageThreshold = Number(this.graphDetail.upperBound);
      }

      if (this.graphDetail.messageTypeSelected === "Dead Letter") {
        this.updateSubscription.deadLetterMessageThreshold = Number(this.graphDetail.upperBound);
      }

      if (this.graphDetail.messageTypeSelected === "Scheduled Msgs") {
        this.updateSubscription.scheduledMessageThreshold = Number(this.graphDetail.upperBound);
      }

      const result = await UpdateSubscription(this.subGraph.organisationId, this.subGraph.id, this.updateSubscription);

      this.graphDetail.description = result.data.body.description ?? "";
      this.graphDetail.monitorName = result.data.body.displayName;
      this.loadDataGraph();
      if (this.$props.onRefresh) {
        this.$props.onRefresh(); // Call the parent’s refresh method directly
      }
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.graphDetail.isLoading = false;
  }

  prepareViewData(result: SubscriptionGraph): void {
    this.subscriptionHistory = result.history;
    this.subGraph = result;
    this.graphDetail.updatedAtFormatted = dayjsEx.utc(result.updatedAt).format(updateAtFormat);
    this.updateSubscription.displayName = result.displayName;
    this.updateSubscription.description = result.description ?? "";
    this.updateSubscription.activeMessageThreshold = result.activeMessageTreshold;
    this.updateSubscription.deadLetterMessageThreshold = result.deadLetterMessageTreshold;
    this.updateSubscription.scheduledMessageThreshold = result.scheduledMessageTreshold;

    this.graphDetail.description = result.description ?? "";
    this.graphDetail.monitorName = result.displayName;
    this.graphDetail.monitorSelected = true;
    this.graphDetail.selectedId = result.id;
    this.graphDetail.healthMonitorName = result.healthMonitorName;

    this.graphDetail.alertConfigHistories = [];
    var list = result.alertConfigHistory?.map((item) => new AlertConfigHistoryResponseModel(item));
    this.graphDetail.alertConfigHistories = list ?? [];
  }
}
