



























import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator"; // Import Watch decorator

@Component({
  props: {
    handleSave: {
      type: Function as () => void,
      required: true,
    },
    handleToggleModal: {
      type: Function as () => void,
      required: true,
    },
    selectedMonitorName: {
      type: String,
      required: true,
    },
    lowerBound: {
      type: Number,
      required: true,
    },
    upperBound: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
})
export default class ThresholdEditor extends Vue {
  // Create a local copy of the prop
  localUpperBound: number = this.$props.upperBound;
  localLowerBound: number = this.$props.lowerBound;

  // Watcher for the prop
  @Watch("upperBound")
  onUpperBoundChange(newVal: number) {
    this.localUpperBound = newVal;
  }

  @Watch("lowerBound")
  onlowerBoundChange(newVal: number) {
    this.localLowerBound = newVal;
  }

  // Emit changes to the parent component
  emitUpperBoundChange() {
    this.$emit("updateUpper", this.localUpperBound);
  }

  emitLowerBoundChange() {
    this.$emit("updateLower", this.localLowerBound);
  }
}
