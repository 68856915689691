import authGuard from "@/utils/auth/authRouteGuard";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Webbeat from "../views/webbeat/ProductsView.vue";
import ProductDetail from "../views/webbeat/ProductDetail.vue";
import ComponentDetail from "../views/webbeat/ComponentDetail.vue";
import Dashboard from "../views/dashboards/Dashboard.vue";
import CreateFlow from "../views/dashboards/CreateFlow.vue";
import isDevEnvGuard from "@/utils/auth/isDevEnvGuard";
import EditComponentMonitors from "@/views/dashboards/EditComponentMonitors.vue";
import EditTeamMember from "@/views/dashboards/EditTeamMember.vue";
import ComponentPlayground from "@/views/ComponentPlayground.vue";
import Home from "@/views/Home.vue";
import LibraryManager from "../views/lighthouse/LibraryManager.vue";
import PackagesView from "@/views/lighthouse/PackagesView.vue";
import BuildDetail from "@/views/lighthouse/BuildDetail.vue";
import Builds from "@/views/lighthouse/BuildsView.vue";
import PackageView from "@/views/lighthouse/PackageView.vue";
import PackageDetail from "@/views/lighthouse/PackageDetail.vue";
import GraphDetailTrend from "@/views/webbeat/GraphDetailTrend.vue";
import GraphDetailSubscription from "@/views/webbeat/GraphDetailSubscription.vue";
import GraphDetailQueue from "@/views/webbeat/GraphDetailQueue.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Root",
    beforeEnter: authGuard,
  },
  {
    path: "/home",
    name: "Home",
    beforeEnter: authGuard,
    component: Home,
  },
  {
    path: "/libraryManager",
    name: "LibraryManager",
    beforeEnter: authGuard,
    component: LibraryManager,
    redirect: "/packages",
    children: [
      {
        path: "/builds",
        component: Builds,
      },
      {
        path: "/builds/detail",
        component: BuildDetail,
      },
      {
        path: "/packages",
        component: PackagesView,
      },
      {
        path: "/package",
        component: PackageView,
      },
      {
        path: "/package/detail",
        component: PackageDetail,
      },
    ],
  },
  {
    path: "/webbeat",
    name: "Webbeat",
    beforeEnter: authGuard,
    component: Webbeat,
  },
  {
    path: "/auth/callback",
    name: "AuthCallback",
    component: () => import(/* webpackChunkName: "AuthCallback" */ "../views/auth/Callback.vue"),
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    beforeEnter: authGuard,
    props: true,
    component: ProductDetail,
  },
  {
    path: "/product/:pid/component/:cid",
    name: "ComponentDetail",
    beforeEnter: authGuard,
    props: true,
    component: ComponentDetail,
  },
  {
    path: "/organisation/:orgId/product/:pid/name/:propName/trend",
    name: "GraphDetailTrend",
    beforeEnter: authGuard,
    props: true,
    component: GraphDetailTrend,
  },
  {
    path: "/organisation/:orgId/product/:pid/name/:propName/subscription",
    name: "GraphDetailSubscription",
    beforeEnter: authGuard,
    props: true,
    component: GraphDetailSubscription,
  },
  {
    path: "/organisation/:orgId/product/:pid/name/:propName/queue",
    name: "GraphDetailQueue",
    beforeEnter: authGuard,
    props: true,
    component: GraphDetailQueue,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    beforeEnter: authGuard,
    props: true,
    component: Dashboard,
  },
  {
    path: "/dashboard/add",
    name: "CreateFlow",
    beforeEnter: authGuard,
    props: true,
    component: CreateFlow,
  },
  {
    path: "/dashboard/editComponentMonitors",
    name: "EditComponentMonitors",
    beforeEnter: authGuard,
    props: true,
    component: EditComponentMonitors,
  },
  {
    path: "/dashboard/editTeamMember",
    name: "EditTeamMember",
    beforeEnter: authGuard,
    props: true,
    component: EditTeamMember,
  },
  {
    path: "/dev/components",
    beforeEnter: isDevEnvGuard,
    component: ComponentPlayground,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
