










































import Component from "vue-class-component";
import Vue from "vue";
import ToastrHelper from "@/helpers/toastHelper";
import { GetProducts } from "@/api/apiWebbeat";
import ProductBase from "@/typing/webbeat/productBase.class";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    Loader,
  },
})
export default class Webbeat extends Vue {
  isLoading = false;

  get items(): ProductBase[] {
    if (this.$store.state.webbeatProducts.length === 0) {
      return [];
    }
    return this.$store.state.webbeatProducts;
  }

  get groupedItems(): { [orgName: string]: ProductBase[] } {
    const grouped: { [orgName: string]: ProductBase[] } = {};

    this.items.forEach((item) => {
      const orgName = item.organisation.name;

      if (!grouped[orgName]) {
        grouped[orgName] = [];
      }

      grouped[orgName].push(item);
    });

    return grouped;
  }

  async mounted(): Promise<void> {
    this.isLoading = true;
    const items: ProductBase[] = [];
    try {
      const result = await GetProducts();
      result.data.body
        .sort((a: ProductBase, b: ProductBase) => {
          const sortResult = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

          return sortResult;
        })
        .forEach((item: ProductBase) => {
          items.push(new ProductBase(item));
        });

      this.$store.commit("SET_WEBBEAT_PRODUCTS", items);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.isLoading = false;
  }

  async goToProductDetail(item: ProductBase) {
    this.$router.push({
      name: "ProductDetail",
      params: { id: item.id.toString() },
    });
  }

  back() {
    this.$router.push({
      name: "Home",
    });
  }
}
