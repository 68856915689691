import { ChartValueItem } from "./graphs/chartValueItem.class";

export default class ChartDataModel extends ChartValueItem {
  date: string;
  value: number;
  receivedAtDate: Date;
  formattedDate: string;

  constructor(c?: ChartDataModel) {
    super(c);
    this.date = c?.date ?? "";
    this.value = c?.value ?? 0;
    this.receivedAtDate = c?.receivedAtDate ?? new Date();
    this.formattedDate = c?.formattedDate ?? "";
  }
}
