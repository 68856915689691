import AlertConfigHistoryResponseModel from "../alertConfigHistoryResponseModel.class";
import ChartDataModel from "../chartDataModel.class";

export default class GraphDetail {
  isLoading = false;
  updatedAtFormatted = "";
  trendKey = "";
  chartData: ChartDataModel[] = [];
  chartDataLine: any[] = [];
  chartLower: any[] = [];
  chartUpper: any[] = [];
  chartGap: any[] = [];
  chartLabels: any[] = [];
  chartDataSet: any;
  chartOptions: any;
  healthMonitorName = "";
  highestValue = 0;
  description = "";
  monitorName = "";
  boundariesLabel = "";
  monitorSelected = false;
  selectedId = 0;
  lowerBound = 0;
  upperBound = 0;
  alertConfigHistories: AlertConfigHistoryResponseModel[] = [];
  pastTimeSelected = 7;
  messageTypeSelected = "Active";
}
