import { ChartValueItem } from "./graphs/chartValueItem.class";

export default class TrendValue extends ChartValueItem {
  receivedAtDate: Date;
  count: number;

  constructor(t?: TrendValue) {
    super(t);
    this.receivedAtDate = t?.receivedAtDate ?? new Date();
    this.count = t?.count ?? 0;
  }
}
