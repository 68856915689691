import TrendValue from "./trendValue.class";

export default class TrendHistory {
  updatedAt: Date;
  key: string;
  healtClientName: string;
  values: TrendValue[];

  constructor(t?: TrendHistory) {
    this.updatedAt = t?.updatedAt ?? new Date();
    this.key = t?.key ?? "";
    this.healtClientName = t?.healtClientName ?? "";
    this.values = [];
    if (t && t.values) {
      this.values = t.values.map((x) => new TrendValue(x));
    }
  }
}
