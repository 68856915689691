













































































































import Component from "vue-class-component";
import Vue from "vue";
import ToastrHelper from "@/helpers/toastHelper";
import InfraComponent from "@/typing/webbeat/component.class";
import Loader from "@/components/Loader.vue";
import Server from "@/typing/webbeat/server.class";
import Trend from "@/typing/webbeat/trend.class";
import Monitor from "@/components/Monitor.vue";
import ServerComponent from "@/components/ServerComponent.vue";
import Subscription from "@/typing/webbeat/subscription.class";
import LineChart from "@/components/Line.vue";
import QueueModel from "@/typing/webbeat/queueModel.class";
import { hasPermissionSync } from "@/utils/auth";
import { Permission } from "@/typing/permissions.enum";
import BoundaryHistory from "@/components/Graph/BoundaryHistory.vue";
import ThresholdEditor from "@/components/Graph/ThresholdEditor.vue";
import GraphDetailTrend from "@/views/webbeat/GraphDetailTrend.vue";
import GraphDetailQueue from "@/views/webbeat/GraphDetailQueue.vue";
import GraphDetailSubscription from "@/views/webbeat/GraphDetailSubscription.vue";
import storeTypes from "@/store/storeTypes";
import { GetComponent, UpdateComponentDescription } from "@/api/apiWebbeat";

@Component({
  components: {
    Loader,
    Monitor,
    ServerComponent,
    LineChart,
    BoundaryHistory,
    ThresholdEditor,
    GraphDetailTrend,
    GraphDetailSubscription,
    GraphDetailQueue,
  },
  props: {
    pid: String,
    cid: String,
  },
})
export default class ComponentDetail extends Vue {
  isLoading = false;
  itemModel = new InfraComponent();
  componentName = "";
  componentDescription = "";
  newComponentDescription = "";
  servers: Server[] = [];
  trends: Trend[] = [];
  subscriptions: Subscription[] = [];
  queues: QueueModel[] = [];
  orderedList: (Trend | Subscription | QueueModel)[] = [];
  isDisrupted = true;
  discriptionEditing = false;
  isTrendSelected = false;
  isSubscriptionSelected = false;
  isQueueSelected = false;
  orgId = "";
  propName = "";

  isAdminAppUpdate(): boolean {
    return hasPermissionSync(Permission.adminappUpdate);
  }

  async refresh() {
    this.isLoading = true;
    try {
      this.scrollToTop();
      const result = await GetComponent(this.$props.cid);

      this.itemModel = new InfraComponent(result.data.body);
      this.componentName = this.itemModel.name;
      this.componentDescription = this.newComponentDescription = this.itemModel.description;
      this.isDisrupted = this.itemModel.isDisrupted;
      this.servers = [];
      this.queues = [];
      this.trends = [];
      this.subscriptions = [];

      this.itemModel.servers.forEach((item: Server) => {
        this.servers?.push(item);
      });

      this.itemModel.trends.forEach((item: Trend) => {
        this.trends?.push(new Trend(item));
      });

      this.itemModel.subscriptions.forEach((item: Subscription) => {
        this.subscriptions?.push(new Subscription(item));
      });

      this.itemModel.queues.forEach((item: QueueModel) => {
        this.queues?.push(new QueueModel(item));
      });

      const list = [...this.trends, ...this.subscriptions, ...this.queues];
      this.orderedList = list.sort((a, b) => a.order - b.order);
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }
    this.isLoading = false;
  }

  async mounted(): Promise<void> {
    this.refresh();
  }

  isTrend(item: any) {
    return item instanceof Trend;
  }

  isSubscription(item: any) {
    return item instanceof Subscription;
  }

  isQueueModel(item: any) {
    return item instanceof QueueModel;
  }

  editDescription() {
    this.discriptionEditing = !this.discriptionEditing;
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling smooth
    });
  }

  async selectTrend(item: Trend) {
    this.orgId = item.organisationId.toString();
    this.propName = item.displayName;
    this.isTrendSelected = true;
    this.isSubscriptionSelected = false;
    this.isQueueSelected = false;
  }

  async selectSubscription(item: Subscription) {
    this.orgId = item.organisationId.toString();
    this.propName = item.displayName;
    this.isTrendSelected = false;
    this.isSubscriptionSelected = true;
    this.isQueueSelected = false;
  }

  async selectQueue(item: QueueModel) {
    this.orgId = item.organisationId.toString();
    this.propName = item.displayName;
    this.isTrendSelected = false;
    this.isSubscriptionSelected = false;
    this.isQueueSelected = true;
  }

  cancelComponent() {
    this.newComponentDescription = this.componentDescription;
    this.discriptionEditing = false;
  }

  async saveDescription() {
    this.isLoading = true;
    try {
      const result = await UpdateComponentDescription(this.itemModel.id, this.newComponentDescription);
      this.componentDescription = this.newComponentDescription = result.data.body.description;
      this.$store.dispatch(storeTypes.actions.UPDATE_COMPONENT, {
        model: result.data,
      });
      this.discriptionEditing = false;
    } catch (e) {
      ToastrHelper.errorToastr(this.$t("DefaultErrorMessage") as string, this);
    }

    this.isLoading = false;
  }

  back() {
    this.$router.push({
      name: "ProductDetail",
      params: { id: this.$props.pid },
    });
  }
}
