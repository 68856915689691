























































import Component from "vue-class-component";
import Vue from "vue";
import AlertConfigHistoryResponseModel from "@/typing/webbeat/alertConfigHistoryResponseModel.class";

@Component({
  props: {
    item: AlertConfigHistoryResponseModel,
  },
})
export default class BoundaryHistory extends Vue {}
